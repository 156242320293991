.publish-feature {
  .kite-modal-dialog {
    padding: 40px;
    min-width: 550px;
    width: 550px;
  }

  .kite-modal-header {
    display: none;
  }
  .kite-modal-body {
    padding: 0;
    text-align: left;
  }

  .kite-modal-title {
    text-align: left;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  &__copy {
    text-align: left;
    margin-bottom: 30px;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 17px;
  }

  &__button {
    width: 210px;
  }
}
