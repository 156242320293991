.swimlane-unsaved-changes {
  position: relative;
  border-radius: 4px;
  border: solid 1px #ee4d56;
  width: 100%;
  padding: 36px;
  background-color: #fff;
  max-width: 1600px;
  margin: 0 auto 16px;

  h2 {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 1.25;
    color: #003561;
    margin-bottom: 20px;
  }

  &__copy {
    line-height: 1.88;
    color: #003561;
    margin-bottom: 0;
  }

  &__btn {
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
