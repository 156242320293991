.filename-modal {
  .kite-modal-dialog {
    padding: 40px;
    min-width: 955px;
    width: 955px;
  }

  .kite-modal-header {
    display: none;
  }
  .kite-modal-body {
    padding: 0;
    text-align: left;
  }

  .kite-modal-title {
    text-align: left;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
    text-transform: capitalize;
  }

  &__list {
    width: 873px;
    max-height: 239px;
    overflow: scroll;
    border: solid 1px #d8dde6;
    padding: 0;
    margin: 0 0 24px 0;
  }

  &__option {
    cursor: pointer;
    list-style: none;
    padding: 18px;
    border-bottom: solid 1px #d8dde6;

    &:hover {
      background-color: #f8f8f8;
    }

    &:last-of-type {
      border: none;
    }

    &--selected {
      border-left: solid 4px #0073d1 !important;
      padding-left: 14px;
    }
  }

  .kite-loader {
    margin: 50px 0;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: center;

    .kite-btn {
      margin-right: 16px;
    }
  }
}
