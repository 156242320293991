.episode-result {
  border: 1px solid #d8dde6;
  border-radius: 4px;
  background-color: #fff;
  padding: 16px;
  display: flex;
  position: relative;
  margin-bottom: 17px;

  &--search {
    width: 49%;
  }

  &__image {
    margin-right: 32px;
  }

  &__content {
    margin-right: 16px;
    flex: 1;

    .info {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      p {
        margin-bottom: 0;
      }

      .label {
        font-weight: bold;
        margin-right: 8px;
      }

      .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: #000;
        margin: 0 8px 0 0;
      }

      &:last-child {
        align-items: flex-start;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__badge {
    text-transform: capitalize;
    display: inline-block;
    border-radius: 13px;
    padding: 0px 20px;

    &--existing {
      background-color: #cfd0d2;
      color: #63738a;
    }

    &--new {
      background-color: #31a2ff;
      color: #fff;
    }
  }

  &__link {
    display: inline-block;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #0073d1;
    color: #0073d1;
    min-width: 130px;
    max-width: 100%;
    padding: 8px 20px;
    transition: background-color 195ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 0.25rem;
    text-decoration: none;

    .link__content {
      display: flex;
      align-items: center;
      font-weight: 500;
      white-space: nowrap;

      .kite-icon {
        height: 20px !important;
        width: 20px !important;
        margin-right: 8px;
      }
    }

    &:hover {
      color: #fff;
      background-color: #0073d1;
      text-decoration: none;
    }
  }

  &__button {
    .kite-icon {
      height: 20px !important;
      width: 20px !important;
    }
  }
}
