.delete-feature-card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 40px 24px;

  &__title {
    font-size: 24px;
    color: #003561;
  }

  &__copy {
    color: #000;
  }

  &__button {
    margin-bottom: 16px;
    .kite-btn-outline-primary {
      border-color: #d6312b;
      color: #d6312b;

      &:hover,
      &:focus {
        background-color: #d6312b;
        color: #fff;
      }
    }
  }
}
