.category-drawer {
  .drawer__close {
    top: 40px;
    right: 40px;
  }

  h2 {
    margin-bottom: 30px;
  }

  .kite-input {
    max-width: 100%;
  }

  &__submit {
    margin: 32px auto;
  }

  &__delete {
    &-card {
      position: relative;
      width: 517px;
      height: 111px;
      padding: 16px;
      margin-top: 32px;
      border-radius: 4px;
      border: solid 1px #d8dde6;
      background-color: #ffffff;
    }

    p {
      line-height: 1.5px;
    }

    &-title {
      font-weight: bold;
      margin-bottom: 4px;
    }

    &-copy {
      width: 260px;
    }

    &-button {
      position: absolute;
      top: 16px;
      right: 16px;
      min-width: 143px;

      .kite-btn-outline-primary {
        border-color: #d6312b;
        color: #d6312b;

        &:hover,
        &:focus {
          background-color: #d6312b;
          color: #fff;
        }
      }
    }
  }
}
