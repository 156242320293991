.swimlane-preview {
  .kite-modal-dialog {
    background-color: #001019 !important;
  }

  .kite-modal-body {
    padding-bottom: 8px;
  }

  h1 {
    font-size: 36px;
  }

  &__wrapper {
    height: 700px;
    overflow: scroll;
    margin-bottom: 16px;
  }

  &__swimlane {
    width: 500px;
    margin: 0 auto 8px;
    text-align: left;
  }

  &__scroll {
    display: flex;
    overflow-y: scroll;
  }

  img {
    margin-right: 8px;
  }
}
