.add-titles {
  position: relative;
  padding: 16px 40px 30px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__back-link {
    width: 250px;
    height: 44px;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1;
    color: #0073d1;
    text-decoration: none;
    margin-bottom: 16px;
    text-transform: capitalize;

    .kite-icon {
      margin-right: 12px;
    }
  }

  h2 {
    align-self: flex-start;
    width: 100%;
    line-height: normal;
    margin-bottom: 14px;
  }

  &__filter-wrapper {
    display: flex;
    flex-direction: row;

    .kite-select {
      width: 263px;
      margin-right: 16px;
    }
  }

  .search-bar {
    margin-bottom: 16px;

    &__input-wrapper {
      margin: 0 8px 0 0;
    }
  }

  &__checkbox {
    display: block;
  }

  &__poster {
    width: 32px;
    height: 48px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin-left: 16px;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;

    > * {
      margin-left: 16px;
    }

    p {
      color: #63738a;
      margin-bottom: 0;
    }
  }
}
