.filter-menu {
  display: inline-block;
  position: relative;

  &__button {
    max-width: 126px;
    min-width: 126px;

    &[aria-expanded='true'] {
      .kite-btn-outline-primary {
        background-color: #0062b2;
        color: #fff;
      }
    }
  }

  &__dropdown {
    padding: 32px 32px 0;
    position: relative;
    bottom: -8px;

    &-container {
      width: 355px;
      position: absolute;
      z-index: 10;
    }
  }

  &__options {
    h3 {
      line-height: 100%;
      margin-bottom: 16px;
    }

    .kite-custom-checkbox {
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }

    & + section {
      margin-top: 24px;
    }
  }

  .kite-radio {
    label {
      margin-bottom: 8px;
    }
  }
}
