.asset-card {
  padding: 40px 40px;

  &__title {
    font-size: 24px;
    margin-bottom: 16px;
    color: #003561;
  }

  &__field {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 0 16px 0;

    &-title {
      display: inline-block;
      font-weight: bold;
      text-transform: capitalize;
      margin-right: 5px;
    }
  }

  &__select-wrapper {
    display: flex;

    & > * {
      margin-right: 16px;
    }
  }
}
