.category {
  position: relative;
  padding: 16px 40px 30px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__back-link {
    width: 182px;
    height: 44px;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1;
    color: #0073d1;
    text-decoration: none;
    margin-bottom: 16px;

    .kite-icon {
      margin-right: 12px;
    }
  }

  &__button-wrapper {
    position: absolute;
    top: 16px;
    right: 40px;
    display: flex;
    flex-direction: row;

    .kite-btn {
      margin-left: 16px;
    }
  }

  &__title {
    align-self: flex-start;
    width: 100%;
    line-height: normal;
  }

  &__remove-btn {
    .kite-btn-outline-primary {
      border-color: #d6312b;
      color: #d6312b;
      &:hover,
      &:focus {
        color: white;
        background-color: #d6312b;
      }
    }
  }
}
