.network-card {
  padding: 40px 40px 24px;

  &__title {
    font-size: 24px;
    color: #003561;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .kite-alert {
      font-size: 16px;
      margin-left: 8px;
    }
  }

  &__select {
    &-categories {
      margin-bottom: 16px;
    }
    &-primary {
      margin-top: 24px;
      margin-bottom: 4px;
    }
  }

  &__categories {
    &-container {
      .primary-label {
        font-size: 14px;
        color: #0073d1;
      }
    }

    .pill__value__container {
      font-size: 1rem;

      &:hover {
        background-color: #d8dde6;
      }
    }
  }

  &__caption {
    font-size: 14px;
  }
}
