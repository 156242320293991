.asset-type-picker {
  .kite-modal-dialog {
    max-width: 566px;
  }

  .kite-modal-body {
    padding: 0 0 40px;
  }
  .body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__assets {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
    margin: 24px 0 40px;
    &-button {
      width: 235px;
      height: 235px;
      background: none;
      border-radius: 4px;
      border: 2px solid #0073d1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: transform 195ms cubic-bezier(0.4, 0, 0.2, 1);
      .title {
        font-size: 24px;
        color: #0073d1;
        margin-top: 8px;
        transition: inherit;
      }

      &:hover,
      &:focus {
        transform: translateY(-4px);
      }
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    margin-top: 30px;
    padding-left: 35px;
    text-align: left;

    &-copy {
      color: #63738a;
      margin: 0;
    }

    &-button {
      .kite-btn__content {
        color: #c2332a;
      }
    }
  }
}
