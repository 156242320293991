.search-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__input-wrapper {
    padding: 0;
    position: relative;
    width: 100%;
    margin: 0 8px 0 16px;
  }

  &__input {
    margin: 0;
    padding: 0 2rem 0 2.4rem;
    height: 42px;
    width: 100%;

    &:focus,
    &:active {
      border: 2px solid #0960a8;
      outline: none;
    }
  }

  &__icon {
    cursor: default;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-42%);
    opacity: 0.5;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    color: #63738a;
  }

  &__clear {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    opacity: 0.5;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    color: #63738a;
  }

  &__button {
    min-width: 158px;
  }
}
