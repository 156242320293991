.series {
  padding: 16px 40px;

  & > * {
    margin: 0 auto 16px;
    max-width: 1200px;
  }
}

.season {
  margin: 0 auto;
  max-width: 1600px;
  padding: 38px 40px 16px;

  & > * {
    margin: 0 0 16px;
    max-width: 1600px;
  }

  &__select {
    max-width: 385px;
  }

  h3 {
    margin: 16px 0 20px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.25;
    color: #003561;
  }

  &__none {
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;

    &-title {
      width: 377px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.25;
      text-align: center;
      color: #63738a;
      margin: 24px 0;
    }

    &-copy {
      font-size: 16px;
    }
  }
}
