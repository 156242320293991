.metadata {
  border: 1px solid #d8dde6;
  &__top {
    background-color: white;
    padding: 36px 42px 24px;

    h2 {
      font-size: 24px;
      color: #003561;
      margin-right: 10px;
    }
  }

  &__heading-wrapper {
    display: flex;
    align-items: center;
  }

  &__copy {
    margin: 0 16px 0 0;
    height: fit-content;
  }

  &__title {
    font-weight: bold;
    margin-right: 8px;
  }

  &__button--update {
    margin-right: 16px;
  }

  &__inputs {
    display: flex;
    align-items: center;
  }

  &__card {
    width: 100%;
    background-color: #003561;
    color: white;
    padding: 32px 66px 24px 42px;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  &__content {
    display: flex;
  }

  &__images {
    min-width: 548px;
  }

  &__image {
    &-wrapper {
      display: inline-block;
      margin-right: 8px;

      p {
        font-size: 16px;
        font-weight: 500;
        margin: 0 9px 0 0;
        line-height: 1.19;
      }
    }

    &-caption {
      display: flex;
      align-items: center;

      a {
        display: flex;
      }
    }
  }

  &__poster {
    width: 145px;
    height: 218px;
    margin-bottom: 8px;
  }

  &__epic {
    width: 387px;
    height: 218px;
    margin-bottom: 8px;
  }

  &__details {
    margin-left: 16px;
    max-width: 540px;
  }

  &__fields-wrapper {
    min-height: 248px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__field {
    font-size: 16px;
    margin: 0;

    &-title {
      font-weight: bold;
      text-transform: capitalize;
      margin-right: 5px;
    }

    &-description {
      display: block;
      margin-bottom: 6px;
    }
  }

  &__bad {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    p {
      font-size: 24px;
      font-weight: 500;
    }
  }

  &--new {
    .metadata__card {
      padding: 40px 48px;
      background-color: #fff;
      color: #000;
    }

    .metadata__fields-wrapper {
      min-height: 100%;
    }
  }
}
