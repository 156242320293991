.swimlane-status {
  position: relative;
  border-radius: 4px;
  border: solid 1px #e1e5ed;
  width: 100%;
  padding: 24px;
  background-color: #fff;
  max-width: 1600px;
  margin: 0 auto 16px;

  h2 {
    font-size: 24px;
    line-height: 1.25;
    color: #003561;
  }

  &__copy {
    line-height: 1.88;
    color: #003561;
    margin-bottom: 8px;
  }

  &__dates {
    display: flex;
  }

  &__date {
    line-height: 1.88;
    color: #003561;
    margin-right: 8px;
    margin-bottom: 0;

    &-title {
      font-weight: bold;
      margin-right: 4px;
    }
  }

  &__edit-btn {
    margin-left: 8px;
    .kite-btn__content {
      font-weight: 500;
      color: #0073d1;
    }
  }

  &__cta {
    margin-left: 16px;
    margin-bottom: 4px;
    &-wrapper {
      display: flex;
      position: absolute;
      top: 24px;
      right: 24px;

      &--column {
        flex-direction: column;

        .swimlane-status__date {
          margin: 0;
          text-align: right;
        }
      }
    }

    &--red {
      .kite-btn-outline-primary {
        border-color: #d6312b;
        color: #d6312b;
        &:hover,
        &:focus {
          color: white;
          background-color: #d6312b;
        }
      }
    }

    &--disabled {
      .kite-btn-outline-primary {
        border-color: #63738a !important;
        background-color: #fff !important;
        color: #63738a !important;
      }
    }
  }

  &--expired {
    color: #d6312b;
  }
}
