.add-drawer {
  .drawer__close {
    top: 40px;
    right: 40px;
  }

  h2 {
    margin-bottom: 30px;
  }

  .kite-select,
  .kite-input {
    max-width: 100%;
  }

  &__upload {
    .rkp-autosuggest__label {
      font-weight: 400;
    }
    .kite-icon {
      height: 23px !important;
      width: 23px !important;
    }

    .icon-folder {
      fill: #0073d1;
    }

    &-error {
      line-height: 1.2;
      margin: 0.25rem 0 0 0;
      color: #d6312b;
    }

    .rkp-file-drop__inline-text {
      width: 400px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__location {
    margin-top: 38px;
    margin-bottom: 2px;
    &-caption {
      font-size: 14px;
    }
  }

  &__submit {
    margin: 32px auto;
  }

  &__delete {
    &-card {
      position: relative;
      width: 517px;
      height: 111px;
      padding: 16px;
      margin-top: 32px;
      border-radius: 4px;
      border: solid 1px #d8dde6;
      background-color: #ffffff;
    }

    p {
      line-height: 1.5px;
    }

    &-title {
      font-weight: bold;
      margin-bottom: 4px;
    }

    &-copy {
      width: 260px;
    }

    &-button {
      position: absolute;
      top: 16px;
      right: 16px;
      min-width: 143px;

      .kite-btn-outline-primary {
        border-color: #d6312b;
        color: #d6312b;

        &:hover,
        &:focus {
          background-color: #d6312b;
          color: #fff;
        }
      }
    }
  }

  &__filename {
    &-card {
      position: relative;
      width: 517px;
      height: 116px;
      padding: 16px;
      margin-top: 32px;
      margin-bottom: 20px;
      border-radius: 4px;
      border: solid 1px #d8dde6;
      background-color: #ffffff;
    }

    p {
      line-height: 1.5px;
    }

    &-title {
      font-weight: bold;
      margin-bottom: 36px;
    }

    &-copy {
      width: 260px;
    }

    &-button {
      position: absolute;
      top: 16px;
      right: 16px;
      min-width: 143px;
    }
  }
}
