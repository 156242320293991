.table-status {
  &__status {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-icon {
      margin-right: 9px;
    }

    &-err {
      width: 100%;
      margin-left: 29px;
      font-size: 12px;
      color: #d6312b;
    }
  }
}
