.swimlanes {
  position: relative;
  padding: 16px 40px 30px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 0 auto 16px;
  }

  &__title {
    align-self: flex-start;
    width: 100%;
    line-height: normal;
    margin-bottom: 30px !important;
  }

  &__create-btn {
    position: absolute;
    top: 16px;
    right: 40px;
  }

  &__card {
    position: relative;
    margin-right: 16px;
    padding: 16px;
    border-radius: 4px;
    border: solid 1px #d8dde6;
    background-color: #ffffff;
    width: 65%;
    height: fit-content;

    &-wrapper {
      display: flex;
      width: 100%;
    }

    &--prod {
      width: 33%;
      margin-right: 0;
    }

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.3;
    }

    &-copy {
      font-size: 14px;
      line-height: 1.43;
    }

    &-cta {
      position: absolute;
      top: 16px;
      right: 16px;
      display: flex;

      .kite-btn {
        margin-left: 16px;
      }
    }
  }

  &__tile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    margin: 16px 0;
    padding: 18px 18px 18px 32px;
    border-radius: 4px;
    border: solid 1px #d8dde6;

    &--published {
      border: solid 1px #008516;
    }

    &-name {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      margin: 0;
    }

    &-link {
      display: flex;
      align-items: center;
      width: fit-content;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #0073d1;

      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &-details {
      font-size: 14px;
      letter-spacing: -0.16px;
    }

    &-status {
      font-weight: bold;

      &--expired {
        color: #d6312b;
      }
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__create-modal {
    .kite-modal-dialog {
      max-width: 600px;
    }

    .kite-modal-body {
      padding: 0px 56px 40px 56px;
    }

    .kite-modal-title {
      text-align: left;
    }
  }

  &__deploy {
    &-modal {
      .kite-modal-dialog {
        text-align: left;
        max-width: 522px;
      }

      .kite-modal-body {
        padding: 0px 56px 40px 56px;
      }
    }

    &-tiles {
      max-height: 350px;
      overflow: scroll;
      margin-bottom: 16px;
    }
  }
}
