.fingerprint-files {
  padding: 48px 48px 60px;

  h3 {
    font-size: 24px;
    color: #003561;
    margin-bottom: 1rem;
  }

  &__tag {
    display: flex;
    align-items: center;
    height: 24px;
    margin: 0 8px 8px 0;

    &-close {
      margin-left: 9px;
    }

    &-wrapper {
      margin-top: 20px;
    }
  }
}
