.swimlane {
  h2 {
    font-size: 24px;
    line-height: 1.25;
    color: #003561;
  }
  &__subheader {
    width: 100%;

    margin: 0;
    padding: 26px 42px 26px 48px;
    background-color: #003561;

    &-content {
      max-width: 1600px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-right {
      display: flex;
      align-items: center;
    }

    h1 {
      margin: 0 30px 0 0;
      font-size: 24px;
      letter-spacing: -0.28px;
      color: #ffffff;
    }

    &-btn {
      justify-self: flex-start;
      .kite-btn__content {
        color: #fff;
        font-weight: 500;
      }
    }

    &-status {
      margin: 0;
      color: #fff;
      &--bold {
        font-weight: bold;
      }
    }
  }

  &__alert {
    padding-left: 38px;
    .kite-alert__inner {
      width: 100%;
      max-width: 1600px;
    }
  }

  &__content {
    padding: 16px 42px 24px 38px;
    align-items: center;
  }

  &__sub-content {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
