.expiration-card {
  padding: 40px 40px 24px;

  &__heading-wrapper {
    display: flex;
    align-items: center;
  }

  h3 {
    margin-bottom: 16px;
  }

  &__title {
    font-size: 24px;
    color: #003561;
    margin-right: 10px;
  }

  &__subtitle {
    color: #9ba9bd;
  }

  &__button {
    position: absolute;
    top: 40px;
    right: 40px;
    margin-bottom: 16px;
  }

  .pill__value {
    max-width: unset !important;
    &__container {
      font-size: 1rem;

      &:hover {
        background-color: #d8dde6;
      }
    }
  }
}
