.feature-services {
  position: relative;
  padding: 40px 40px 8px;

  &__heading-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title {
    font-size: 24px;
    color: #003561;

    margin-right: 10px;
  }

  &__button-wrapper {
    position: absolute;
    top: 40px;
    right: 40px;
    display: flex;
    flex-direction: row;
  }

  &__button {
    margin-left: 16px;
  }

  &__card-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    & > * {
      margin: 0 14px 16px 0;
    }

    & > *:nth-of-type(3n) {
      margin-right: 0;
    }

    & > *:nth-of-type(6n) {
      margin-right: 0;
    }
  }

  &__none {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  @media screen and (max-width: 1278px) {
    &__card-wrapper {
      & > *:nth-of-type(3n) {
        margin-right: 14px;
      }

      & > *:nth-of-type(2n) {
        margin-right: 0;
      }

      & > *:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }
}
