.files-card {
  position: relative;
  padding: 40px 40px 24px;

  &__heading-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 8px;
  }

  &__title {
    font-size: 24px;
    color: #003561;
    line-height: 1.25;
    margin-right: 10px;
  }

  &__add-button {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  &__file {
    &-field {
      display: flex;
    }
    &-details {
      position: relative;
      border-bottom: solid 1px #d8dde6;
      padding-bottom: 8px;
      margin-top: 16px;
    }

    &-name {
      font-size: 20px;
      font-weight: 500;
    }

    &-title {
      display: inline-block;
      font-weight: bold;
      margin-right: 5px;
    }
  }

  &__button-wrapper {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
  }

  &__remove-button {
    .kite-btn__content {
      color: #d6312b;
    }
  }

  &__none {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
}
