.search-modal {
  .kite-modal-dialog {
    max-width: 1200px;
    border-radius: 4px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.5);
    padding: 5px 48px;
  }

  .kite-modal-title {
    font-size: 24px;
    font-weight: bold;

    line-height: 1.25;
    color: #000000;
    text-align: left;
    margin: 0;
  }

  .search__select,
  .add-movie__title,
  .add-episode__title {
    display: none;
  }

  .add-movie__results-card,
  .add-episode__results-card {
    border: none;
    padding: 0;
  }

  .search {
    padding: 0;

    .title,
    .info {
      text-align: left;
    }

    .episode-result,
    .movie-result {
      width: 100%;
    }
  }
}
