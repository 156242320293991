.notes-card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 40px 24px;

  &__heading-wrapper {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    color: #003561;
    margin-right: 10px;
  }

  &__subtitle {
    color: #9ba9bd;
  }

  &__button {
    margin-bottom: 16px;
  }
}
