.series-data {
  position: relative;
  padding: 40px 40px 24px;

  &__title {
    font-size: 24px;
    color: #003561;
    margin-bottom: 1rem;
  }

  &__badge {
    position: absolute;
    top: 40px;
    right: 40px;

    display: inline-block;
    border-radius: 13px;
    padding: 0px 20px;

    background-color: #31a2ff;
    color: #fff;
  }

  &__field {
    font-size: 16px;
    margin: 0;

    &-title {
      font-weight: bold;
      text-transform: capitalize;
      margin-right: 5px;
    }
  }
}
