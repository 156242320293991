.status-bar {
  position: relative;
  padding: 40px;

  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #003561;
  }

  &__copy {
    font-size: 16px;
    color: #003561;
    line-height: 1.88;
    margin: 0;
  }

  .rkp-stepper {
    width: 601px;
    margin: 0 auto;
    padding-bottom: 0;

    &__step,
    &__label {
      cursor: unset;
    }

    &__step:nth-of-type(1) {
      .rkp-stepper__circle--active {
        border: 2px solid #003561;

        .ki {
          fill: #003561 !important;
        }
      }
    }

    &__step:nth-of-type(2) {
      .rkp-stepper__circle--active {
        border: 2px solid #63738a;

        .ki {
          fill: #63738a !important;
        }
      }
    }

    &__step:nth-of-type(3) {
      .rkp-stepper__circle--active {
        border: 2px solid #31a2ff;

        .ki {
          fill: #31a2ff !important;
        }
      }
    }

    &__step:nth-of-type(4) {
      .rkp-stepper__circle--active {
        border: 2px solid #008516;

        .ki {
          fill: #008516 !important;
        }
      }
    }
  }

  &__button {
    margin-left: 20px;

    &-wrapper {
      position: absolute;
      display: flex;
      top: 43px;
      right: 43px;
    }

    &--red {
      .kite-btn-outline-primary {
        border-color: #d6312b;
        color: #d6312b;
        &:hover,
        &:focus {
          color: white;
          background-color: #d6312b;
        }
      }
    }
  }
}
