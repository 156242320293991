.movies-table {
  width: 100%;

  .rkp-table__cell {
    overflow: visible;
    align-items: flex-start;
  }

  .rkp-table__hoverable:hover,
  .rkp-table__hoverable:focus {
    background-color: #fff;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 6px;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}
