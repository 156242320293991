.swimlane-ready-modal {
  .kite-modal-dialog {
    padding: 40px 40px 30px;
    min-width: 550px;
    width: 550px;
    min-height: 200px;
  }

  .kite-modal-header {
    display: none;
  }
  .kite-modal-body {
    padding: 0;
    text-align: left;
  }

  .kite-modal-title {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .kite-modal__action-container {
    margin-top: 30px;
  }

  .rkp-datepicker {
    margin: 0 0 16px 0;
  }

  &__checkbox {
    margin: 0;
  }

  &__subtext {
    font-size: 12px;
    letter-spacing: -0.14px;
    margin: -8px 0 14px 36px;
  }
}
