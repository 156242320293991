.all-categories {
  position: relative;
  padding: 16px 40px 30px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__create-btn {
    position: absolute;
    top: 16px;
    right: 40px;
  }

  &__title {
    align-self: flex-start;
    width: 100%;
    line-height: normal;
  }

  &__switch {
    margin-top: 2px;
  }
}
