.new-assets-table {
  width: 100%;

  .rkp-table__cell {
    overflow: visible;
    align-items: flex-start;
  }

  &__cta {
    display: flex;
    justify-content: flex-end;
    padding: 0 18px;
    font-size: 16px;
    font-weight: 500;
    color: #0073d1;

    .kite-btn__content {
      padding: 3px 16px;
      height: unset;
      margin: -4px 0;
    }
  }

  &__exp {
    padding-left: 16px;

    &-title {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
