.subheader {
  width: 100%;
  padding: 20px 40px;
  background-color: #003561;

  &__content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin: 0;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__tabs {
    margin: 30px 0 -16px 0;

    .kite-tabs__tab {
      margin: 0;
    }
    .kite-tabs__tab-content {
      font-size: 16px;
      color: white;
      width: 180px;
    }

    .kite-tabs__tab--active {
      border-bottom: 2px solid white;
    }
  }

  &__status {
    position: absolute;
    top: 16px;
    right: 40px;
    display: flex;
    align-items: center;
    color: white;

    &-title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      margin-right: 9px;
    }

    &-icon {
      margin-right: 9px;
    }
  }

  &__back {
    margin: 0 0 18px 0;
    height: 44px;
    .kite-btn {
      &__content {
        color: white;
        font-size: 16px;
        font-weight: 500;
      }

      &__icon {
        height: 22px !important;
        width: 22px !important;
        margin-left: -6px !important;
      }
    }
  }

  &__episode-data {
    font-size: 20px;
    font-weight: 500;
    color: white;
    margin: 4px 0 -4px 0;
  }
}
