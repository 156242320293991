.search {
  padding: 16px 30px;
  max-width: 1600px;
  margin: 0 auto;

  &__select {
    margin-bottom: 32px;
    margin-right: 16px;

    select {
      text-transform: capitalize;
    }
  }

  &__input-wrapper {
    display: flex;

    .search-bar {
      margin-top: 20px;
    }
  }

  &__results-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .add-episode__no-results {
    width: 400px;
  }
}
