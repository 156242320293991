.service-card {
  width: 363px;
  border-radius: 4px;
  background-color: #f8f8f8;
  padding: 16px;

  &__heading-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #686868;
    margin: 0 0 0 16px;
  }

  &__field {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 0 8px 0;

    &-title {
      display: inline-block;
      font-weight: bold;
      text-transform: capitalize;
      margin-right: 5px;
    }

    &-icon {
      margin-right: 5px;
    }
  }

  &__download {
    display: inline-flex;
    max-width: 300px;
    overflow: hidden;
    color: #008cff;
    text-decoration: none;

    &-text {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 265px;
    }
  }

  &__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;

    .kite-btn__content {
      height: 36px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__remove {
    width: 323px;
    margin: 8px auto 0;
    .kite-btn__content {
      color: #d6312b;
      border-color: #d6312b;

      &:hover {
        color: white;
        background-color: #d6312b;
      }
    }
  }
}
