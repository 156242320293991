.unpublished-services {
  .kite-modal {
    &-header {
      display: none;
    }

    &-dialog {
      max-width: 577px;
      padding: 40px;
      text-align: left;
    }

    &-body {
      padding: 0;
    }
  }

  .kite-radio__button {
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
}
