.service-icon {
  margin-right: 10px;
  position: relative;

  &:hover {
    .ki {
      fill: #0073d1 !important;
    }

    .service-icon__bubble-bg {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      left: -80px;
      top: -70px;
    }
  }

  &__bubble-bg {
    position: absolute;
    z-index: 10;
    height: auto;
    text-align: left;
    background-color: transparent;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(99, 115, 138, 0.4);
    transform: translateY(-50%);
    pointer-events: none;

    top: calc(100% + 14px);
    bottom: auto;
    transform: none;
    visibility: hidden;
  }

  &__bubble {
    position: static;
    display: block;
    width: max-content;
    top: auto;
    left: auto;
    transform: none;
    z-index: 100;
    padding: 8px 20px;

    text-align: center;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.4em;
    color: black;
    background-color: white;
    border: 1px solid #d8dde6;
    border-radius: 4px;

    .invisible-hover-area {
      position: absolute;
      height: 24px;
      width: 24px;
      background-color: transparent;
    }

    &:before,
    &:after {
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      position: absolute;
      transform: rotate(45deg) translateY(50%);
      z-index: -1;
    }

    &:before {
      z-index: -1;
      box-shadow: 1px 0px 6px rgba(99, 115, 138, 0.4);
    }

    &:after {
      z-index: -1;
      background-color: #fff;
      top: calc(100% - 6px);
      left: 75px;
      bottom: auto;
      right: auto;
      transform: rotate(45deg) translateY(-50%);
      border: 1px 0 0 1px solid #d8dde6;
    }
  }
}
