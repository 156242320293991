.episodes-table {
  .rkp-table__cell {
    overflow: visible;
    align-items: flex-start;
  }
  .rkp-table__hoverable:hover,
  .rkp-table__hoverable:focus {
    background-color: #fff;
  }
}
