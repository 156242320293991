.status-counter {
  display: flex;
  width: 100%;
  padding: 18px;

  &__status {
    flex: 1;
    text-align: center;

    &:not(:last-child) {
      border-right: 1px solid #d8dde6;
    }
  }

  &__count {
    font-size: 40px;
    margin-bottom: 0;
    line-height: 1.25;
    letter-spacing: -1px;
    color: #060606;
  }

  &__label {
    font-size: 20px;
    margin-bottom: 0;
  }
}
