.remove-feature-update {
  .kite-modal-dialog {
    padding: 40px 40px 30px;
    min-width: 550px;
    width: 550px;
    min-height: 200px;
  }

  .kite-modal-header {
    display: none;
  }
  .kite-modal-body {
    padding: 0;
    text-align: left;
  }

  .kite-modal-title {
    text-align: left;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .kite-modal__action-container {
    margin-top: 30px;
  }
}
