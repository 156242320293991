.swimlane-tiles {
  position: relative;
  width: 43%;
  max-width: 784px;
  height: fit-content;
  padding: 16px;
  border-radius: 4px;
  border: solid 1px #e1e5ed;
  background-color: #ffffff;

  &__subheader {
    font-size: 12px;
    margin-left: 8px;
  }

  &__copy {
    max-width: 435px;
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 12px;
  }

  &__select-wrapper {
    display: flex;
  }

  &__tile {
    position: relative;
    display: flex;
    padding: 16px;
    border-radius: 4px;
    border: solid 1px #d8dde6;
    margin-bottom: 8px;

    &-order {
      font-weight: bold;
      letter-spacing: -0.19px;
      color: #2f313a;
      margin: 8px 0;
    }

    &-details {
      margin-left: 24px;
    }

    &-link {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.19px;
      color: #0073d8;
      text-decoration: none;
      margin-bottom: 4px;

      &:hover {
        text-decoration: underline;
      }
    }

    &-genre {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      margin-bottom: 4px;
    }

    &-status {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      &--bold {
        font-weight: bold;
      }
    }

    &-btn {
      margin-right: 16px;

      .kite-btn__content {
        font-weight: 500;
        color: #0073d1;
      }

      &--in {
        height: 26px;
        width: 130px;
        margin-bottom: 0;
        font-weight: 500;
        text-align: center;
        color: #adadad;
      }

      &-wrapper {
        position: absolute;
        top: 16px;
        right: 16px;

        display: flex;
        align-items: center;
      }
    }

    .kite-con-menu {
      &__button {
        color: #0073d1;
        margin: 0 auto;
      }

      &__menu {
        min-width: 240px;

        &-item-btn {
          text-align: left;
        }
      }
    }

    &-placeholder {
      margin: 9px auto;
      width: 100%;
      font-weight: 500;
      text-align: center;
      color: #63738a;
    }
  }
}
