.add-movie {
  padding: 16px 30px;
  max-width: 1600px;
  margin: 0 auto;

  & > * {
    margin-bottom: 16px;
  }

  &__back-link {
    width: 182px;
    height: 44px;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1;
    color: #0073d1;
    text-decoration: none;
    margin-bottom: 16px;

    .kite-icon {
      margin-right: 12px;
    }
  }

  h2 {
    color: #2f313a;
    letter-spacing: -0.28px;
    margin-bottom: 32px;
    line-height: normal;
  }

  &__step,
  &__title {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.28px;
    color: #003561;
    line-height: normal;
    margin-bottom: 16px;
  }

  &__results-card {
    padding: 32px 48px;
    min-height: 772px;
    position: relative;
    z-index: 1;

    .search-bar {
      margin-bottom: 32px;

      &__input-wrapper {
        margin: 0 16px 0 0;
      }
    }
  }
  &__no-results {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    width: 274px;
    font-size: 24px;
    line-height: 1.25;
    color: #9a9a9a;
  }

  &__icon-shadow,
  &__loader {
    position: absolute;
    z-index: -1;

    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .feature-services__button--publish {
    display: none !important;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
      margin-left: 30px;
    }
  }
}
