.new-assets {
  position: relative;
  padding: 16px 40px 30px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    align-self: flex-start;
    width: 100%;
    line-height: normal;
    margin-bottom: 30px !important;
  }

  & > * {
    margin: 0 auto 16px;
  }

  &__up-to-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }

  &__subheader {
    font-size: 24px;
    font-weight: bold;
    color: #63738a;
  }

  &__copy {
    margin: 0;
    line-height: 1.88;
  }

  .search-bar__input-wrapper {
    margin: 0 8px 0 0;
  }
}
