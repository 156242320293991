.series-table {
  width: 100%;

  .rkp-table__cell {
    align-items: flex-start;
  }

  .rkp-table__hoverable:hover,
  .rkp-table__hoverable:focus {
    background-color: #fff;
  }
}
