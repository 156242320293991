.languages-card {
  padding: 40px 40px 24px;
  &__title {
    font-size: 24px;
    color: #003561;
    margin-bottom: 1rem;
  }

  &__languages {
    .pill__value__container {
      font-size: 1rem;

      &:hover {
        background-color: #d8dde6;
      }
    }
  }
}
