.swimlane-collection {
  position: relative;
  width: 56%;
  max-width: 784px;
  min-height: 614px;
  height: fit-content;

  padding: 16px;
  border-radius: 4px;
  border: solid 1px #e1e5ed;
  background-color: #ffffff;

  &__add-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__copy {
    max-width: 400px;
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 11px;
  }

  &__totals {
    font-weight: bold;
    letter-spacing: -0.19px;
    color: #003564;
  }

  &__tile {
    position: relative;
    display: flex;
    padding: 16px;
    border-radius: 4px;
    border: solid 1px #d8dde6;
    margin-bottom: 8px;

    &-details {
      margin-left: 24px;
    }

    &-link {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.19px;
      color: #0073d8;
      text-decoration: none;
      margin-bottom: 4px;

      &:hover {
        text-decoration: underline;
      }
    }

    &-genre {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      margin-bottom: 4px;
    }

    &-status {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    &-btn {
      margin-right: 16px;

      .kite-btn__content {
        font-weight: 500;
        color: #0073d1;
      }

      &--in {
        height: 26px;
        width: 130px;
        margin-bottom: 0;
        font-weight: 500;
        text-align: center;
        color: #adadad;
      }

      &-wrapper {
        position: absolute;
        top: 16px;
        right: 16px;

        display: flex;
        align-items: center;
      }
    }
  }

  .kite-loader {
    width: 16px;
  }

  &__empty {
    text-align: center;
    width: 100%;
    max-width: 366px;
    margin: 122px auto;
    opacity: 0.4;

    &-copy {
      margin: 50px auto 0;
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      color: #5db8fc;
    }
  }
}
