.errors-card {
  position: relative;
  padding: 40px 40px 24px;
  border: solid 1px #d6352f;

  &__title {
    font-size: 24px;
    color: #d6352f;
    margin-bottom: 7px;
  }

  &__button {
    position: absolute;
    top: 40px;
    right: 44px;
  }

  ul {
    color: #003561;
    padding-left: 20px;
  }

  li {
    line-height: 1.88;
  }
}
